(function() {
  $(function() {
    return $('input[data-behavior="tokeninput"]').each(function() {
      var $input, url;
      $input = $(this);
      url = $input.attr('data-src');
      if (url != null) {
        return $input.tokenInput(url, {
          preventDuplicates: true
        });
      }
    });
  });

}).call(this);
