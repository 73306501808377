(function() {
  $(function() {
    var disable_team_select, enable_team_select;
    enable_team_select = function(wrapper) {
      wrapper.find('.disabled').removeClass('disabled');
      wrapper.find('select:disabled').prop('disabled', false);
      return wrapper.removeClass('hidden');
    };
    disable_team_select = function(wrapper) {
      wrapper.find('div.input, select').addClass('disabled');
      wrapper.find('select:not(disabled)').prop('disabled', false);
      return wrapper.addClass('hidden');
    };
    return $('select#user_team_id').change(function(evt) {
      var optgroup_label, selected_option;
      selected_option = $(this).find('option:selected');
      optgroup_label = selected_option.parent().attr('label');
      if (/extern/i.test(optgroup_label)) {
        return $('#user_groups').prop('disabled', true).addClass('hidden');
      } else {
        return $('#user_groups').prop('disabled', false).removeClass('hidden');
      }
    });
  });

}).call(this);
