(function() {
  var Archives;

  Archives = (function() {
    function Archives(_at_container) {
      this.container = _at_container;
      this.loadArchives();
    }

    Archives.prototype.setupArchives = function() {
      return this.container.children('[data-archive]').each((function(_this) {
        return function(index, element) {
          var $elem, key;
          $elem = $(element);
          key = $elem.attr('data-archive');
          if (/^\d{4}$/.test(key)) {
            return _this.bindYear(key, $elem);
          } else {
            return _this.bindMonth(key, $elem);
          }
        };
      })(this));
    };

    Archives.prototype.loadArchives = function() {
      return $.get(this.container.attr('data-url'), (function(_this) {
        return function(html) {
          _this.container.html(html);
          _this.setupArchives();
          return _this.container.show();
        };
      })(this));
    };

    Archives.prototype.bindYear = function(key, $elem) {
      var $childs;
      $childs = $elem.nextAll("[data-archive*='" + key + "/']").hide();
      return $elem.click(function() {
        $childs.show();
        return $elem.remove();
      });
    };

    Archives.prototype.bindMonth = function(key, $elem) {
      return $elem.click((function(_this) {
        return function() {
          var xhr;
          if ($elem.data('alertsLoaded')) {
            $elem.next().show();
            return $elem.hide();
          } else if (!$elem.data('alertsLoading')) {
            $elem.find('.loading').show();
            xhr = $.get(_this.container.attr('data-url') + '/' + key, function(html) {
              var $html;
              $html = $(html).insertAfter($elem.hide().data('alertsLoaded', true));
              $html.children('[data-archive="hide"]').bind("click", function() {
                $html.hide();
                return $elem.show();
              });
              return $elem.find('.loading').hide();
            });
            return $elem.data('alertsLoading', xhr);
          }
        };
      })(this));
    };

    return Archives;

  })();

  $(function() {
    var $archives, archives;
    $archives = $('[data-behavior="archives"]:first').hide();
    return archives = new Archives($archives);
  });

}).call(this);
