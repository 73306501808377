// Public: Makes child elements of this parent clickable, if their
// data-href returns something useful. Does currently not support
// live events (!).
//
// Example:
//
//     <ul data-behavior="clickable">
//       <li data-href="/foo">Foo</li>
//       <li data-href="/bar">Bar</li>
//     </ul>
//
// Returns nothing.
(function() {
  $(document).on('click', '[data-behavior="clickable"] > article', function(evt) {
    var url;
    url = this.getAttribute('data-href');
    if (url != null) {
      return location.href = url;
    }
  });

}).call(this);
