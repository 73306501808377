/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'jquery'
import 'jquery-ui'
import 'jquery-ujs'
// require jquery.purr
import 'jquery-tokeninput'
import 'jquery.transit'

import 'scripts/vendor/gems.js.erb'

import 'scripts/behaviors/alerts.js'
import 'scripts/behaviors/archives.js'
import 'scripts/behaviors/clickable.js'
import 'scripts/behaviors/datepicker.js'
import 'scripts/behaviors/dismiss.js'
import 'scripts/behaviors/escalation.js'
import 'scripts/behaviors/nav.js'
import 'scripts/behaviors/submit.js'
import 'scripts/behaviors/tabs.js'
import 'scripts/behaviors/tokeninput.js'
import 'scripts/behaviors/users.js'

$(function() {

  // enable best in place
  $('.best_in_place').best_in_place();

  // enable token input
  /*
  $('input.tokeninput').each(function() {
    var $tk = $(this);
    var src = $tk.attr('data-src');
    if (src) $tk.tokenInput(src, {
      preventDuplicates: true
    });
  });*/
});