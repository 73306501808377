// Public: Elements with data-behavior="dismiss" do inherit the ability
// to dismiss the it's parent container on click. Uses a neat fade out
// effect.
//
// Example:
//
//     <div>
//       <p>Some message</p>
//       <a data-behavior="dismiss">Dismiss</a>
//     </div>
//
// Returns nothing.
(function() {
  $(document).on('click', '[data-behavior="dismiss"]', function(evt) {
    var $el;
    evt.preventDefault();
    $el = $(this).parent();
    return $el.transition({
      opacity: 0
    }, function() {
      return $el.remove();
    });
  });

}).call(this);
