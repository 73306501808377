$(document).on("ajax:complete", 'a[data-behavior="edit_escalation"]', function(event, data, status, xhr) {
  // response will come underneath of ‘data’ variable
  $('#escalation').replaceWith(data.responseText);

  // rebind tokeninput
  $('#escalation input[data-behavior="tokeninput"]').each(function() {
    var $input  = $(this);
    var url     = $input.data('src');
    var users   = $input.data('tokeninput');

    if(url.length > 0 ) {
      $input.tokenInput(url, {preventDuplicates: true, prePopulate: users})
    }
  });
});

$(document).on("ajax:complete", '[data-behavior="update_escalation"]', function(event, data, status, xhr) {
  console.log('update')
  // response will come underneath of ‘data’ variable
  $('#escalation').replaceWith(data.responseText);
});
