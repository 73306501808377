(function() {
  $(function() {
    return $('input[data-behavior="datepicker"]').each(function() {
      return $(this).datepicker({
        dateFormat: $(this).data('date-format')
      });
    });
  });

}).call(this);
