(function() {
  $(function() {
    $('input#alert_has_external_teams').change(function(evt) {
      var wrapper;
      wrapper = $('div#alert_external_teams');
      if ($(this).is(':checked')) {
        wrapper.removeClass('hidden');
        wrapper.find('.disabled').removeClass('disabled');
        return wrapper.find(':input').prop('disabled', false);
      } else {
        wrapper.addClass('hidden');
        wrapper.find('.input').addClass('disabled');
        return wrapper.find(':input').addClass('disabled').prop('disabled', true);
      }
    });
    $('a#copy_alert_description').click(function(evt) {
      var external_description, internal_description;
      internal_description = $('#alert_description:input');
      external_description = $('#alert_description_external:input');
      external_description.val(internal_description.val());
      return evt.preventDefault();
    });
    $('a#copy_activity_comment').click(function(evt) {
      var external_comment, internal_comment;
      internal_comment = $('#activity_comment');
      external_comment = $('#activity_comment_external');
      external_comment.val(internal_comment.val());
      return evt.preventDefault();
    });
    $('#activity_has_external_teams:input').change(function(evt) {
      var fields;
      fields = $('#activity_external_settings .fields');
      if ($(this).is(':checked')) {
        fields.removeClass('hidden');
        return fields.find(':input').prop('disabled', false);
      } else {
        fields.addClass('hidden');
        fields.find(':input').prop('disabled', true);
        return fields.find('.external_team_id:checked').prop('checked', false);
      }
    });
    if (window.localStorage.length > 0) {
      $('form.edit_alert, form.new_activity').submit(function(evt) {
        var external_team_ids;
        external_team_ids = $(this).find('.external_team_id:checked').map(function() {
          return $(this).attr('id');
        });
        external_team_ids = JSON.stringify($.makeArray(external_team_ids));
        return window.localStorage.setItem($(this).attr('action'), external_team_ids);
      });
      $('form.new_activity, form.edit_alert').each(function() {
        var e, external_team_ids;
        try {
          external_team_ids = JSON.parse(window.localStorage.getItem($(this).attr('action')));
          $(this).find('.external_team_id').each(function() {
            if (external_team_ids.indexOf($(this).attr('id')) > -1) {
              return $(this).prop('checked', true);
            } else {
              return $(this).prop('checked', false);
            }
          });
          if ($(this).find('.external_team_id:checked').length > 0) {
            return $(this).find('.has_external_teams').prop('checked', true).trigger('change');
          }
        } catch (_error) {
          e = _error;
        }
      });
      return true;
    }
  });

}).call(this);
