// Public: Allows for simple JS tab bars uses the rel attribute to
// set active styles et all. This is only supposed to work with ul/li
// combos.
//
// Examples:
//
//   <div>
//     <ul data-behavior="tabs">
//       <li data-rel="uno" class="active">1. Tab</li>
//       <li data-rel="duo">Second Tab</li>
//     </ul>
//     <div data-tab="uno" class="active">First tab.</div>
//     <div data-tab="duo">Second tab.</div>
//   </div>
//
(function() {
  $(document).on('click', '[data-behavior="tabs"] > *', function() {
    var $el, $parent, $tab;
    $el = $(this);
    $parent = $el.parent('ul').parent();
    $tab = $parent.find('[data-tab="' + $el.attr('data-rel') + '"]');
    $parent.find('.active').removeClass('active');
    $el.addClass('active');
    return $tab.addClass('active');
  });

}).call(this);
